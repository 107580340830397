import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ModalContent = ({ closeModal, modalData }) => {
  return (
    <div className="beny_tm_modalbox_service">
      <button className="close-modal" onClick={closeModal}>
        <img src="/img/svg/cancel.svg" alt="close icon" />
      </button>
      {/* End close icon */}

      <div className="box_inner">
        <div className="description_wrap scrollable">
          <div className="popup_informations">
            <div className="modal-img-1">
              <img src={modalData?.img} alt="" />
            </div>
            <div className="description des-modal">
              <div className="head-modal-d1">
                <div className="head-modal-d2">
                  <h1>{modalData?.title}</h1>
                  <h3>{modalData?.heading}</h3>
                </div>

                <h2>
                  mrp{" "}
                  <span
                    style={{ textDecoration: "line-through", color: "#f52225" }}
                  >
                    ₹{modalData?.mrp}
                  </span>
                  <br /> selling price{" "}
                  <span style={{ color: "#f52225" }}>₹{modalData?.Price}</span>
                </h2>
              </div>
              <div>
                <button className="modal-button1">Buy Now</button>
              </div>

              <div className="in_list">
                <ul>
                  <li>
                    <p>{modalData?.description1}</p>
                  </li>
                  <li>
                    <p>{modalData?.description2}</p>
                  </li>
                  <li>
                    <p>{modalData?.description3}</p>
                  </li>
                  <li>
                    <p>{modalData?.description4}</p>
                  </li>
                  <li>
                    <p>{modalData?.description5}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End box inner */}
    </div>
  );
};

const ServiceAnimation = () => {
  const [modalData, setModalData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = (item) => {
    setIsOpen(!isOpen);
    setModalData(item);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalData(null);
  };

  const plans = [
    {
      id: 1,
      title: "Keto Diet Plan",
      heading: "Lose 4kg In 4weeks",
      mrp: 2000,
      Price: 750,
      img: "/img/sid/Diet/diet1.jpeg",
      description1:
        "1.⁠ ⁠Comprehensive Keto Diet Plan: Access a detailed, step-by-step guide to adopting both vegetarian and non-vegetarian keto diets seamlessly into your lifestyle.",
      description2:
        "2.⁠ ⁠Easy and Sustainable Recipes: Follow along with recorded recipe videos showcasing easy-to-make and sustainable keto meals, tailored to both vegetarian and non-vegetarian preferences",
      description3:
        "3.⁠ ⁠Targeted Weight Loss: Whether vegetarian or non-vegetarian, aim to shed 4kg in just 4 weeks by adhering to the prescribed dietary guidelines of the keto diet.",
      description4:
        "4.⁠⁠ ⁠Lifestyle Integration: Learn how to seamlessly integrate the keto diet, regardless of dietary preference, into your daily routine for long-term success and sustainability.",
      description5:
        "5.⁠ Supportive Resources: Access additional resources and tips specific to both vegetarian and non-vegetarian keto diets to ensure success on your journey to a healthier lifestyle.",
    },
    {
      id: 2,
      title: "High Protein Based Diet",
      heading: "Without Protein Powder & Supplements",
      mrp: 4000,
      Price: 1500,
      img: "/img/sid/Diet/diet2.jpeg",
      description1:
        "1. Complete High-Protein Indian Food Plan: Get a simple guide with veg and non-veg options to help you get slim and fit by eating Indian food with lots of protein.",
      description2:
        "2. Tasty and Healthy Recipes: Watch easy videos for making yummy and healthy high-protein meals inspired by Indian cooking to support your fitness goals.",
      description3:
        "3. ⁠Clear Fitness Goals: Decide what you want for your body, like getting toned or losing weight, and use these recipes to help you reach those goals.",
      description4:
        "4.⁠ ⁠Fit into Your Routine: Learn how to easily fit these protein-rich Indian dishes into your daily life so you can stay healthy and fit for the long term.",
      description5:
        "5.⁠ ⁠ ⁠Extra Help When You Need It: Find more tips and resources to support you as you work towards a healthier and fitter you with these high-protein Indian recipes.",
    },
    {
      id: 3,
      title: "diabetes management",
      heading: "Dietary Solutions For Diabetes Control",
      mrp: 2000,
      Price: 650,
      img: "/img/sid/Diet/diet3.jpeg",
      description1:
      "1. Comprehensive Diabetes-Friendly Diet Plan: Gain access to a detailed, step-by-step guide tailored specifically for diabetes patients to control blood sugar levels through diet modification.",
    description2:
      "2. ⁠Easy and Sustainable Recipes: Follow along with recorded recipe videos showcasing easy-to-make and sustainable meals designed to help manage blood sugar levels effectively.",
    description3:
      "3.⁠Targeted Health Goals: With this plan, aim to stabilize blood sugar levels and improve overall health, setting achievable targets for managing diabetes.",
    description4:
      "4.⁠ Lifestyle Integration: Learn how to seamlessly integrate diabetes-friendly dietary changes into your daily routine for long-term blood sugar control and improved well-being.",
    description5:
      "5.⁠ Supportive Resources: Access additional resources and tips tailored to diabetes management, ensuring you have the necessary support and guidance on your journey to better health.",
    },
    {
      id: 4,
      title: "keto diet plan",
      heading: "Lose 4kg in 4weeks",
      mrp: 2000,
      Price: 500,
      img: "/img/sid/Diet/diet4.jpeg",
      description1:
        "1.⁠ ⁠⁠Comprehensive Veg Keto Diet Plan: Access a detailed, step-by-step guide to adopting a vegetarian keto diet seamlessly into your lifestyle.",
      description2:
        "2.⁠ Easy and Sustainable Recipes: Follow along with recorded recipe videos showcasing easy-to-make and sustainable vegetarian keto meals.",
      description3:
        "3. Targeted Weight Loss: With this plan, aim to shed 4kg in just 4 weeks by sticking to the prescribed dietary guidelines.",
      description4:
        "4.⁠⁠ ⁠Lifestyle Integration: Learn how to seamlessly integrate the vegetarian keto diet into your daily routine for long-term success and sustainability.",
      description5:
        "5.⁠ Supportive Resources: Access additional resources and tips to ensure success on your journey to a healthier lifestyle with the veg keto diet plan.",
    },
  ];
  return (
    <div className="service_list">
      <ul>
        {plans.map((item, index) => (
          <li key={item.id}>
            <div
              className="list_inner"
              onClick={() => toggleModal(item)}
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img className="svg" src={item?.img} alt="" />
              <div className="service_title">
                <h3>
                  {item?.title} <br />
                  {item?.heading} <br />
                  mrp{" "}
                  <span
                    style={{ textDecoration: "line-through", color: "#f52225" }}
                  >
                    ₹{item?.mrp}
                  </span>
                  <br /> selling price{" "}
                  <span style={{ color: "#f52225" }}>₹{item?.Price}</span>
                </h3>
              </div>
              <div className="learn_more">
                Know More<span></span>
              </div>
            </div>

            {/* End Modal Motion Graphy */}
          </li>
        ))}
      </ul>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <ModalContent closeModal={closeModal} modalData={modalData} />
      </Modal>
    </div>
  );
};

export default ServiceAnimation;
