import React from "react";
import Skills from "../skills/SkillsAnimation";
import "./about.css";
import { useNavigate } from "react-router";

const AboutAnimation = () => {


  const navigation = useNavigate();


  return (
    <>
 
      <div className="hc4-1">
        <div className="hc4-2">
          <div
          
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
          
          className="hc4-lh1">
            <div className="hc4-d2">
              <h1>hi, I am SIDDHANT AGRAWAL</h1>
              <p>
                Holder of a Nutrition Diploma and certified by CMR National
                Institute of India as a Fitness Coach.
              </p>

              <img src={"img/sid/about11.jpeg"} alt="" />
            </div>
          </div>

          <div 
          
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
          className="hc4-rh1">
            <div className="insta-head-name">
              <div className="insta-dp">
                <a target="blank" href="https://www.instagram.com/shapeme_diet_expert/">
                  <div className="img-dp-insta">
                    <img src={"img/sid/about.JPG"} alt="" />
                  </div>
                </a>
              </div>
              <div className="insta-user-name">
                <a
                  target="blank"
                  href="https://www.instagram.com/shapeme_diet_expert/"
                >
                  <div className="insta-username">

                  <h1 className="user-name-insta">
                    shapeme_diet_expert
                  
                  </h1>
                  <img style={{width:25}} src="img/sid/insta/tick.png" alt="" />
                  </div>
                </a>

                <p>
                  <span>
                    Siddhant Agrawal <br /> Coach <br /> I Help people lose fat
                    sustainably and easily 💪 <br /> | Featured on News 18 for
                    guiding <br />
                    3000+ on their fat loss journey 🌟
                  </span>
                </p>
              </div>
            </div>

            <div className="img-grid-hc4">
              <div className="hc4-rh2">
                <div className="img-grid-hc42">
                  <a
                    target="blank"
                    className="a-tag-df"
                    href="https://www.instagram.com/p/CmQKQixIbhc/?igsh=MXR4dzJhczdlemRzNg%3D%3D"
                  >
                    <img
                      className="hc5img-grid-1"
                      src={"img/sid/insta/insta1.JPG"}
                      alt=""
                    />
                  </a>
                  <a
                    target="blank"
                    className="a-tag-df"
                    href="https://www.instagram.com/p/CoDDX7dDQmW/"
                  >
                    <img
                      className="hc5img-grid-1"
                      src={"/img/sid/insta/insta6.jpg"}

                      alt=""
                    />
                  </a>
                </div>

                <div className="img-grid-hc43">
                  <a
                    target="blank"
                    className="a-tag-df"
                    href="https://www.instagram.com/p/CmHg9H1v4cu/"
                  >
                    <img
                      className="hc5img-grid-2"
                      src={"img/sid/insta/insta2.PNG"}

                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="hc4-rh2">
                <div className="img-grid-hc43">
                  <a
                    target="blank"
                    className="a-tag-df"
                    href="https://www.instagram.com/p/Cn0Vx2ivmO-/"
                  >
                    <img
                      className="hc5img-grid-2"
                      src={"img/sid/insta/insta9.jpg"}

                      alt=""
                    />
                  </a>
                </div>
                <div className="img-grid-hc42">
                  <a
                    target="blank"
                    className="a-tag-df"
                    href="https://www.instagram.com/p/Cm3TD9Fv9ki/"
                  >
                    <img
                      className="hc5img-grid-1"
                      src={"img/sid/insta/insta4.jpg"}

                      alt=""
                    />
                  </a>
                  <a
                    target="blank"
                    className="a-tag-df"
                    href="https://www.instagram.com/p/C0lmUvDo9Ac/?img_index=2"

                  >
                    <img
                      className="hc5img-grid-1"
                      src={"img/sid/insta/insta5.jpg"}

                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Skills />
    </>
  );
};

export default AboutAnimation;
