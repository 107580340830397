import React, { useState } from "react";
import Modal from "react-modal";
import Social from "../Social";
import { FaRegCirclePlay } from "react-icons/fa6";
Modal.setAppElement("#root");

const BlogAnimation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  const toggleModalOne = () => {};
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }

  const openFacebookLink = () => {
    // Replace the URL with your Facebook video link
    const facebookVideoLink =
      "https://www.facebook.com/watch/?v=646973054112242";
    window.open(facebookVideoLink, "_blank");
  };

  return (
    <>
      <div className="news_list">
        <ul>
          <li data-aos="fade-right" data-aos-duration="1200">
            <div className="list_inner">
              <div className="image" onClick={openFacebookLink}>
                <div className=" play-icon-modal">
                  <FaRegCirclePlay size={50}  />
                </div>

                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "/img/sid/news/news1.jpeg"
                    })`,
                  }}
                ></div>
              </div>

              <div className="details">
                <span>
                  January 17, 2024{" "}
                  <a
                    target="blank"
                    href="https://www.facebook.com/watch/?v=646973054112242"
                  >
                    Know More
                  </a>
                </span>
                <a
                    target="blank"
                    href="https://www.facebook.com/watch/?v=646973054112242"
                  >
                <h3 className="title" onClick={toggleModalOne}>
                     NEWS 18 ODIA
             
                </h3>
                  </a>
              </div>
              {/* End details */}

              {/* Start Modal */}
              <Modal
                isOpen={isOpen}
                onRequestClose={toggleModalOne}
                contentLabel="My dialog"
                className="custom-modal"
                overlayClassName="custom-overlay"
                closeTimeoutMS={500}
              >
                <div className="beny_tm_modalbox_news">
                  <button className="close-modal" onClick={toggleModalOne}>
                    <img src="/img/svg/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner"></div>
                  {/* End box inner */}
                </div>
                {/* End modal box news */}
              </Modal>
              {/* End modal */}
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default BlogAnimation;
