import React from 'react'
import Header2 from '../../components/header/Header2'
import Check1 from './Check1'
import './Check.css'
const CheckOut = () => {
  return (
  <>
  
  <Header2/>

  <Check1/>
  
  </>
  )
}

export default CheckOut