import React from "react";
import "./Head.css";
import { Link } from "react-router-dom";
const Header2 = () => {
  return (
    <div>
      <div className="head1">
        <div className="logo-head2">
          <img src="img/sid/logo/logo.jpeg" alt="brand" />
        </div>

        <Link className="nav-2-a1" to={"/"}>
          <span>Home</span>
        </Link>
      </div>
    </div>
  );
};

export default Header2;
