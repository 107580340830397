import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
import {
  FiHome,
  FiUser,
  FiSettings,
  FiGrid,
  FiCast,
  FiPhoneOutgoing,
} from "react-icons/fi";
import { FiMenu } from "react-icons/fi";
import { styled } from "styled-components";

import { RiMenu3Fill } from "react-icons/ri";
import { GrClose } from "react-icons/gr";

const StyledOffCanvas = styled.aside`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: white;
  z-index: 15;
  color: white;
  transition: left 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;

  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Header = () => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const [open, setopen] = useState(false);
  const Icon = open ? GrClose : RiMenu3Fill;

  return (
    <>
      {/* TOPBAR  */}
      <div className={navbar ? "beny_tm_topbar animate" : "beny_tm_topbar"}>
        <div className="in">
          <div>
            <h1
            style={{ color: navbar ? 'black' : 'white' }}
            
            className="nav-head">India's Leading Health & Wellness Visionary</h1>
            {/* <h1 className="nav-head">
              India's first one-stop solution destination for health & fitness.
            </h1> */}
          </div>
          <div className="topbar_inner">
            <div className="logo">
              <img src="img/sid/logo/logo.jpeg" alt="brand" />
            </div>

            <div className="menu-open">
              <FiMenu onClick={() => setopen(!open)} color={navbar ? `black` : `white`}
size={30} />
            </div>

            <div className="menu">
              <Scrollspy
                className="anchor_nav"
                items={[
                  "home",
                  "about",
                  "service",
                  "portfolio",
                  "news",
                  "contact",
                ]}
                currentClassName="current"
                offset={-88}
              >
                <li>
                  <a   className={` ${navbar? 'nav-a': ''}`} href="#home">
                    <span className="first">Home</span>
                    <span className="second">Home</span>
                  </a>
                </li>
                <li>
                  <a 
                   className={` ${navbar? 'nav-a': ''}`}
                  href="#about">
                    <span className="first">About</span>
                    <span className="second">About</span>
                  </a>
                </li>
                <li>
                  <a
                   className={` ${navbar? 'nav-a': ''}`}
                  href="#service">
                    <span className="first">Service</span>
                    <span className="second">Service</span>
                  </a>
                </li>
                <li>
                  <a 
                   className={` ${navbar? 'nav-a': ''}`}
                  href="#portfolio">
                    <span className="first">Portfolio</span>
                    <span className="second">Portfolio</span>
                  </a>
                </li>
                <li>
                  <a
                  
                  className={` ${navbar? 'nav-a': ''}`}
                  href="#news">
                    <span className="first">News</span>
                    <span className="second">News</span>
                  </a>
                </li>
                <li>
                  <a
                   className={` ${navbar? 'nav-a': ''}`}
                  href="#contact">
                    <span className="first">Contact</span>
                    <span className="second">Contact</span>
                  </a>
                </li>
                <li>
                  <a
                   className={` ${navbar? 'nav-a': ''}`}
                  href="https://themeforest.net/checkout/from_item/33910000?license=regular">
                    <span className="wrapper">
                      <span className="first">Buy Now</span>
                      <span className="second">Buy Now</span>
                    </span>
                  </a>
                </li>
              </Scrollspy>
            </div>
          </div>
        </div>
      </div>
      {/* /TOPBAR */}
      {/* <div className={navbar ? "beny_tm_topbar animate" : "beny_tm_topbar"}>
        <div className="in">
          <div className="topbar_inner">
            <div className="logo">
              <Link to="/">
                <img src="img/logo/dark.png" alt="brand" />
              </Link>
            </div>
            <div className="menu">
              <Scrollspy
                className="anchor_nav"
                items={[
                  "home",
                  "about",
                  "service",
                  "portfolio",
                  "news",
                  "contact",
                ]}
                currentClassName="current"
                offset={-88}
              >
                <li className="current">
                  <a href="#home">
                    <span className="first">Home</span>
                    <span className="second">Home</span>
                  </a>
                </li>
                <li>
                  <a href="#about">
                    <span className="first">About</span>
                    <span className="second">About</span>
                  </a>
                </li>
                <li>
                  <a href="#service">
                    <span className="first">Service</span>
                    <span className="second">Service</span>
                  </a>
                </li>
                <li>
                  <a href="#portfolio">
                    <span className="first">Portfolio</span>
                    <span className="second">Portfolio</span>
                  </a>
                </li>
                <li>
                  <a href="#news">
                    <span className="first">News</span>
                    <span className="second">News</span>
                  </a>
                </li>
                <li>
                  <a href="#contact">
                    <span className="first">Contact</span>
                    <span className="second">Contact</span>
                  </a>
                </li>
                <li>
                  <a href="https://themeforest.net/checkout/from_item/33910000?license=regular">
                    <span className="wrapper">
                      <span className="first">Buy Now</span>
                      <span className="second">Buy Now</span>
                    </span>
                  </a>
                </li>
              </Scrollspy>
            </div>
          </div>
        </div>
      </div> */}
      {/* End mobile-menu */}

      <StyledOffCanvas isOpen={open}></StyledOffCanvas>
      <StyledOffCanvasOverlay isOpen={open} onClick={() => setopen(false)} />
    </>
  );
};

export default Header;
