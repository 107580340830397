import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";

const Portfolio = () => {
  const [isOpen, setOpen] = useState(false);
  const [isOpen2, setOpen2] = useState(false);

  const transformations = [
    { id: 8, img: "img/testimonials/test8.PNG", name: "", point: "" },
    { id: 1, img: "img/testimonials/test1.PNG", name: "", point: "" },
    { id: 2, img: "img/testimonials/test2.PNG", name: "", point: "" },
    { id: 3, img: "img/testimonials/test3.PNG", name: "", point: "" },
    { id: 4, img: "img/testimonials/test4.PNG", name: "", point: "" },
    { id: 5, img: "img/testimonials/test5.PNG", name: "", point: "" },
    { id: 6, img: "img/testimonials/test6.PNG", name: "", point: "" },
    { id: 7, img: "img/testimonials/test7.JPEG", name: "", point: "" },
    { id: 9, img: "img/testimonials/test9.PNG", name: "", point: "" },
    { id: 11, img: "img/testimonials/test11.PNG", name: "", point: "" },
    { id: 12, img: "img/testimonials/test12.PNG", name: "", point: "" },
    { id: 13, img: "img/testimonials/test13.JPG", name: "", point: "" },
    { id: 14, img: "img/testimonials/test14.PNG", name: "", point: "" },










  ];

  return (
    <div className="portfolio_list">
      <ul className="gallery_zoom">
        <Gallery>
{transformations.map((item,index)=>(
   <li
   data-aos="fade-right"
   data-aos-duration="1200"
   data-aos-delay="200"
 >
   <div className="list_inner">
     <a
       className="title"
       target="_blank"
       rel="noopener noreferrer"
     >
       <h3>Ave Simone</h3>
       <span>Dribbble Shoot</span>
     </a>

     <Item
   original={item?.img}
   thumbnail={item?.img}
       width={383}
       height={430}
     >
       {({ ref, open }) => (
         <div className="gallery-link">
           <img
             src={item?.img}
             alt="Shoot"
             role="button"
             ref={ref}
             onClick={open}
           />
         </div>
       )}
     </Item>
   </div>
 </li>
))}


       
      
        </Gallery>
      </ul>
    </div>
  );
};

export default Portfolio;
