import React from "react";
import Preview from "../views/Preview";
import HomeLightAnimation from "../views/all-home-version/HomeLightAnimation";

import NotFound from "../views/NotFound";
import { Routes, Route } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
import CheckOut from "../views/all-home-version/CheckOut";

const AllRoutes = () => {
  return (
    <>
      <ScrollTopBehaviour />
      <Routes>
        {/* <Route path="/" element={<Preview />} /> */}
        <Route path="/" element={<HomeLightAnimation />} />
        <Route path="/CheckOut" element={<CheckOut/>} />

        {/* <Route path="/home-dark-animation" element={<HomeDarkAnimation />} /> */}
        {/* <Route path="/home-typer-creative" element={<HomeTyperCreative />} /> */}
        {/* <Route path="/home-parallax-typer" element={<HomeParallaxTyper />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
