import React from "react";

const Check1 = () => {
  return (
    <div className="check-1">
      <div className="check-2">
        <div className="check-lh">
          <div className="check-lh2">
            <h1 className="check-lh-h1">SHAPE ME -ENROLLMENT FORM</h1>
            <div>
              <div className="enroll-form ">
                <div className="div-check-in">
                  <div className="input-check-div2">
                    <input
                      placeholder="Email*"
                      type="text"
                      // value={age}
                      // onChange={handleAgeChange}
                    />
                  </div>
                </div>

                <div className="div-check-in">
                  <div className="input-check-div">
                    <input
                      placeholder="Name*"
                      type="text"

                      // value={age}
                      // onChange={handleAgeChange}
                    />
                  </div>
                  <div className="input-check-div">
                    <input
                      placeholder="Age*"
                      type="number"

                      // value={age}
                      // onChange={handleAgeChange}
                    />
                  </div>
                </div>

                <div className="div-check-in">
                  <div className="input-check-div">
                    <input
                      placeholder=" Height"
                      type="number"
                      // value={age}
                      // onChange={handleAgeChange}
                    />
                  </div>
                  <div className="input-check-div">
                    <input
                      placeholder="Weight*"
                      type="number"
                      // value={age}
                      // onChange={handleAgeChange}
                    />
                  </div>
                </div>
                <div className="div-check-in">
                  <div className="input-check-div">
                    <select>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>

                  <div className="input-check-div">
                    <input
                      placeholder="Contact No*"
                      type="number"
                      // value={age}
                      // onChange={handleAgeChange}
                    />
                  </div>
                </div>

                <div className="div-check-in">
                  <div className="input-check-div">
                    <select>
                      <option value="Choose">
                        WHAT TYPE OF FOOD YOU PREFER? *
                      </option>
                      <option value="PURE VEGETARIAN">PURE VEGETARIAN</option>

                      <option value="NON VEGETARIAN">NON VEGETARIAN</option>
                      <option value="EGGITARIAN">EGGITARIAN</option>
                      <option value="VEGAN">VEGAN</option>
                    </select>
                  </div>

                  <div className="input-check-div">
                    <select>
                      <option value="Choose">
                        ANY PAST WORKOUT EXPERIENCE? *
                      </option>
                      <option value="NONE. I AM BEGINNER">
                        NONE. I AM BEGINNER
                      </option>

                      <option value="3 MONTHS TO 6 MONTHS">
                        3 MONTHS TO 6 MONTHS
                      </option>
                      <option value="6 MONTHS TO 1 YEAR">
                        6 MONTHS TO 1 YEAR
                      </option>
                      <option value="1 YEAR TO 3 YEARS">
                        1 YEAR TO 3 YEARS
                      </option>
                      <option value="3 YEAR OR ABOVE">3 YEAR OR ABOVE</option>
                    </select>
                  </div>
                </div>

                <div className="div-check-in">
                  <div className="input-check-div">
                    <select>
                      <option value="Choose">PREFERABLE WORKOUT TIME? *</option>
                      <option value="MORNING">MORNING</option>

                      <option value="AFTERNOON">AFTERNOON</option>
                      <option value="EVENING">EVENING</option>
                      <option value="NIGHT">NIGHT</option>
                    </select>
                  </div>

                  <div className="input-check-div">
                    <select>
                      <option value="Choose">YOUR FITNESS GOAL?*</option>
                      <option value="FAT LOSS">FAT LOSS</option>

                      <option value="MUSCLE BUILDING">MUSCLE BUILDING</option>
                      <option value="WEIGHT GAIN">WEIGHT GAIN</option>
                      <option value="FITNESS MODEL(6 PACK ABS)">
                        FITNESS MODEL(6 PACK ABS)
                      </option>
                    </select>
                  </div>
                </div>

                <div className="div-check-in">
                  <div className="input-check-div2">
                    <input
                      placeholder="ANY SPECIFIC HEALTH ISSUE,INJURY OR ADDICTION?*"
                      type="text"
                      // value={age}
                      // onChange={handleAgeChange}
                    />
                  </div>
                </div>

                <div className="check-lh-d4">
                  <h1>
                    ANY SPECIFIC THING YOU WANT ME TO TAKE INTO CONSIDERATION{" "}
                    <br />
                    WHILE MAKING YOUR DIET PLAN?
                  </h1>
                  <ul className="check-ul-lh">
                    <li>LIKE/DISLIKE FOR FOOD</li>
                    <li>MEALS TIMING ISSUE</li>
                    <li>ALLERGY FROM ANY FOOD</li>
                    <li>BUDGET FRIENDLY FOOD OPTIONS</li>
                    <li>ANY EXISTING SUPPLEMENT YOU ARE USING NOW</li>
                  </ul>
                  <span>Optional</span>

                  <div className="div-check-in">
                    <div className="input-check-div2">
                      <input
                        placeholder="YOUR ANSWER? *"
                        type="text"
                        // value={age}
                        // onChange={handleAgeChange}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="check-lh-d4">
                  <h1>
                    SHARE YOUR RECENT FRONT PROFILE AND SIDE PROFILE
                    <br />
                    PICTURE IN WHATSAPP FOR BODY ANALYSIS.
                  </h1>
                  <span>optional</span>
                  <div className="div-check-in">
                    <div className="input-check-div2">
                      <input
                        placeholder="YOUR ANSWER? *"
                        type="text"
                        // value={age}
                        // onChange={handleAgeChange}
                      />
                    </div>
                  </div>
                </div> */}

          
              </div>
            </div>
          </div>
        </div>

        <div className="check-rh">
          <div className="chec-rh-f1">
            <h1 className="check-rh-h1">Your order</h1>

            <div className="check-rh-d1">
              <div className="check-rh-d2">
                <h1>Product</h1>
                <h1>Subtotal</h1>
              </div>
              <div className="package-choose">
                <div className="package-choose-d2">
                  <img
                    src="https://shapeme.info/wp-content/uploads/2023/04/diet-1-montj-900x936.jpg"
                    alt=""
                  />
                  <h1>
                    DIET PLAN AND WORKOUT <br /> PLAN FOR 1 MONTH
                  </h1>
                </div>

                <h1>x6</h1>
                <h1>10,000,00</h1>
              </div>

              <div className=" check-rh-d3">
                <h1>Subtotal</h1>

                <h1>₹10,000.00</h1>
              </div>

              <div className=" check-rh-d4">
                <h1>Total</h1>

                <h1>₹10,000.00</h1>
              </div>
            </div>

            <div className="coupon-section">
              <div className="coupon-input-div">

              <input
                placeholder="Coupon Code"
                type="text"
                // value={age}
                // onChange={handleAgeChange}
                />
                </div>
              <button>Apply</button>
            </div>
              <button className="pay-now-check">PAY NOW</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Check1;
